import { Box as ChakraBox, Container, Grid, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import cacheData from 'memory-cache';
import { useRouter } from 'next/router';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { Translations, withTranslations } from 'store/translations';
import { moduleMaxWidth } from 'themes/overrides';
import { getHost } from 'utils/getHost';
import { getNewsOverviewItem, getSectorOrServiceOverviewItem } from 'utils/getOverviewItem';

import { getColorScheme } from '../../lib/colorScheme';
import { OverviewSpotAutomatic as OverviewSpotAutomaticModel } from '../../models/content-types';
import { ContentPage, ContentPage as ContentPageModel } from '../../models/content-types/content_page';
import { OverviewItemModel } from '../../utils/types/overviewItemModel';
import { OverrideItem } from './OverrideItem';
import { OverviewSpotAutomaticAccordion } from './OverviewSpotAutomaticAccordion';
import { OverviewSpotAutomaticGrid } from './OverviewSpotAutomaticGrid';
import { OverviewSpotAutomaticNews } from './OverviewSpotAutomaticNews';

interface OverviewSpotAutomaticProps {
  model: OverviewSpotAutomaticModel;
  parentPageCodename?: string;
}

export const OverviewSpotAutomatic: FunctionComponent<OverviewSpotAutomaticProps> = ({ model, parentPageCodename }) => {
  const { locale } = useRouter();
  const router = useRouter();
  const elements = model.elements;
  const mode = elements.type.value[0]?.codename;
  const bg = getColorScheme(elements.latestNewsBackgroundColor.value[0]?.codename);
  const enableManualOverride = model.elements.enableManualOverride?.value[0]?.codename;
  const manualOverrideItems = model.elements.manualOverviewItems?.linkedItems;
  const sectorHeadlineOverride = model.elements.sectorHeadline;
  const serviceHeadlineOverride = model.elements.serviceHeadline;
  const industryHeadlineOverride = model.elements.industryHeadline;
  const manualItemsHeadlineOverride = model.elements.manualOverrideHeadline;

  const translations = withTranslations();
  //console.log('overview spot automatic ',model)
  // const enableHyphenationHeading = model.elements.enableHyphenationForHeadline.value[0]?.codename == 'yes';
  // const enableHyphenationSummary = model.elements.enableHyphenationForSummary.value[0]?.codename == 'yes';

  const manualServiceItems = model.elements.services.linkedItems.map((page) => {
    return getSectorOrServiceOverviewItem(page, translations, 'service');
  });
  const manualIndustryItems = model.elements.industries.linkedItems.map((page) => {
    return getSectorOrServiceOverviewItem(page, translations, 'industry');
  });
  const manualSectorItems = model.elements.sectors.linkedItems.map((page) => {
    return getSectorOrServiceOverviewItem(page, translations, 'sector');
  });

  const manualNewsItems = model.elements.news.linkedItems.map((page) => {
    return getNewsOverviewItem(page, translations);
  });

  const getOverrideFlags = (type, model) => {
    let overrideText = '';
    const serviceTagOverride = model?.elements.serviceTagOverride;
    const industryTagOverride = model?.elements.industryTagOverride;
    const serviceTagOverrideText = model?.elements.serviceTagOverrideText.value ?? '';
    const industryTagOverrideText = model?.elements.industryTagOverrideText.value ?? '';
    switch (type) {
      case 'service':
        overrideText = serviceTagOverride?.value[0]?.codename == 'yes' ? serviceTagOverrideText : 'no_override';
        return overrideText;
      case 'industry':
        overrideText = industryTagOverride?.value[0]?.codename == 'yes' ? industryTagOverrideText : 'no_override';
        return overrideText;
      default:
        return 'no_override';
    }
  };

  const [serviceItems, setServiceItems] = useState<OverviewItemModel[]>(manualServiceItems);
  const [sectorItems, setSectorItems] = useState<OverviewItemModel[]>(manualSectorItems);
  const [industryItems, setIndustryItems] = useState<OverviewItemModel[]>(manualIndustryItems);
  const [newsItems, setNewsItems] = useState<OverviewItemModel[]>(manualNewsItems);

  const [displayType, setDisplayType] = useState(
    sectorItems.length > 6 ||
      serviceItems.length > 6 ||
      industryItems.length > 6 ||
      elements.numberOfItems.value! > 6 ||
      newsItems.length > 6
      ? 'accordion'
      : 'grid',
  );
  const tabs = [] as Array<{ type: string; label: string }>;
  const host = typeof window !== 'undefined' ? getHost(window) : '';
  if (enableManualOverride) {
    tabs.push({
      type: 'manual_service',
      label:
        manualItemsHeadlineOverride && manualItemsHeadlineOverride.value
          ? manualItemsHeadlineOverride.value
          : translations.common('ourServices'),
    });
  }
  // useEffect(()=>{
  if (serviceItems.length > 0) {
    tabs.push({
      type: 'service',
      label:
        serviceHeadlineOverride && serviceHeadlineOverride.value
          ? serviceHeadlineOverride.value
          : translations.common('relatedServices'),
    });
  }
  if (industryItems.length > 0) {
    tabs.push({
      type: 'industry',
      label:
        industryHeadlineOverride && industryHeadlineOverride.value
          ? industryHeadlineOverride.value
          : translations.common('relatedServices'),
    });
  }
  if (sectorItems.length > 0) {
    tabs.push({
      type: 'sector',
      label:
        sectorHeadlineOverride && sectorHeadlineOverride.value
          ? sectorHeadlineOverride.value
          : translations.common('relatedSectors'),
    });
  }
  // },[serviceItems,sectorItems])
  // useEffect(() => {
  // if(translations.isReady) cacheData.put('translation', translations);
  // }, [translations])
  useEffect(() => {
    cacheData.put('translation', translations);
    if (translations.isReady) {
      const serviceItem = model.elements.services.linkedItems.map((page) => {
        return getSectorOrServiceOverviewItem(page, translations, 'service');
      });
      setServiceItems(serviceItem);
      const industryItem = model.elements.industries.linkedItems.map((page) => {
        return getSectorOrServiceOverviewItem(page, translations, 'industry');
      });
      setIndustryItems(industryItem);
      const sectorItem = model.elements.sectors.linkedItems.map((page) => {
        return getSectorOrServiceOverviewItem(page, translations, 'sector');
      });
      setSectorItems(sectorItem);
      const newsItem = model.elements.news.linkedItems.map((page) => {
        return getNewsOverviewItem(page, translations);
      });
      setNewsItems(newsItem);
    }
  }, [locale, translations.locale, translations.isReady]);
  const fetchPages = async (
    mode: string,
    numberOfItems: number,
    overviewType: string,
    setItems: React.Dispatch<React.SetStateAction<OverviewItemModel[]>>,
    mappingFn: (page: ContentPageModel, translations: Translations) => OverviewItemModel,
    subcategoryCodename?: string,
  ) => {
    // setType(overviewType);
    const subcategoryTags =
      overviewType === 'sector'
        ? model.elements.overviewSpotAutomaticTagsSector.value.map((tag) => tag.codename)
        : overviewType === 'service'
        ? model.elements.overviewSpotAutomaticTagsService.value.map((tag) => tag.codename)
        : overviewType === 'industry'
        ? model.elements.overviewSpotAutomaticTagsIndustry.value.map((tag) => tag.codename)
        : overviewType === 'news'
        ? model.elements.overviewSpotAutomaticTagsSector.value.map((tag) => tag.codename)
        : [];

    const languageArray = ['en', 'da-dk', 'fi-fi', 'de-de', 'no-no', 'sv-se', 'en-apac', 'en-us', 'en-gb'];
    const intersection = languageArray.filter((element) => router.asPath.split('/').includes(element));

    const results = (await fetch(
      `${host}/api/contentPagesWithTags?numberOfItems=${numberOfItems}&overviewType=${overviewType}&subcategoryCodename=${subcategoryCodename}&subcategoryTags=${subcategoryTags.join(
        ',',
      )}&parentPageCodename=${parentPageCodename}&languageParameter=${
        router.asPath.split('/').includes('editors-only') ? intersection : locale
      }`,
    ).then((response) => response.json())) as { pages: ContentPage[] };
    // setResult(results.pages);
    // if (translations.isReady) {
    !!results.pages.length &&
      setItems((manualItems) => {
        return [
          ...manualItems,
          ...results.pages
            .filter((page) => !manualItems.some((item) => item.id === page.system.id))
            .map((page) => {
              return mappingFn(page, translations);
            }),
        ];
      });
    // }

    //  updateItem(results.pages);
    if (results.pages.length > 0 && mode !== 'news') {
      setDisplayType(results.pages.length > 6 ? 'accordion' : 'grid');
    }
  };
  // const updateItem = (pages) => {
  //   if (translations.isReady) {
  //     const newItems: OverviewItemModel[] = [];
  //     pages?.map((page) => {
  //       newItems.push(getSectorOrServiceOverviewItem(page, translations));
  //     });
  //     if (type === 'service') setServiceItems(newItems);
  //     if (type === 'sector') setSectorItems(newItems);
  //     if (type === 'news') setNewsItems(newItems);

  //   }
  // };
  // useEffect(() => {
  //   updateItem(result);
  // }, [result]);

  useEffect(() => {
    if (translations.isReady) {
      if (mode === 'news') {
        fetchPages(
          mode,
          elements.numberOfItems.value || 0,
          // elements.numberOfItems?.value,
          'news',
          setNewsItems,
          getNewsOverviewItem,
          'tags__sector',
        );
      } else {
        if (
          sectorItems.length < (elements.numberOfItems.value || 0) ||
          !!model.elements.overviewSpotAutomaticTagsSector.value.length
        ) {
          fetchPages(
            mode,
            (elements.numberOfItems.value || 0) - sectorItems.length,
            'sector',
            setSectorItems,
            getSectorOrServiceOverviewItem,
            'tags__sector',
          );
        }
        if (
          serviceItems.length < (elements.numberOfItems.value || 0) ||
          !!model.elements.overviewSpotAutomaticTagsService.value.length
        ) {
          fetchPages(
            mode,
            (elements.numberOfItems.value || 0) - serviceItems.length,
            'service',
            setServiceItems,
            getSectorOrServiceOverviewItem,
            'tags__service',
          );
        }
        if (
          industryItems.length < (elements.numberOfItems.value || 0) ||
          !!model.elements.overviewSpotAutomaticTagsIndustry.value.length
        ) {
          fetchPages(
            mode,
            (elements.numberOfItems.value || 0) - industryItems.length,
            'industry',
            setIndustryItems,
            getSectorOrServiceOverviewItem,
            'tags__industry',
          );
        }
      }
    }
  }, [router.locale, router.isReady, translations.locale, translations.isReady]);
  useEffect(() => {
    if (translations.isReady) {
      if (mode === 'news') {
        fetchPages(
          mode,
          elements.numberOfItems.value || 0,
          // elements.numberOfItems?.value,
          'news',
          setNewsItems,
          getNewsOverviewItem,
          'tags__sector',
        );
      } else {
        if (
          sectorItems.length < (elements.numberOfItems.value || 0) ||
          !!model.elements.overviewSpotAutomaticTagsSector.value.length
        ) {
          fetchPages(
            mode,
            (elements.numberOfItems.value || 0) - sectorItems.length,
            'sector',
            setSectorItems,
            getSectorOrServiceOverviewItem,
            'tags__sector',
          );
        }
        if (
          serviceItems.length < (elements.numberOfItems.value || 0) ||
          !!model.elements.overviewSpotAutomaticTagsService.value.length
        ) {
          fetchPages(
            mode,
            (elements.numberOfItems.value || 0) - serviceItems.length,
            'service',
            setServiceItems,
            getSectorOrServiceOverviewItem,
            'tags__service',
          );
        }
        if (
          industryItems.length < (elements.numberOfItems.value || 0) ||
          !!model.elements.overviewSpotAutomaticTagsIndustry.value.length
        ) {
          fetchPages(
            mode,
            (elements.numberOfItems.value || 0) - industryItems.length,
            'industry',
            setIndustryItems,
            getSectorOrServiceOverviewItem,
            'tags__industry',
          );
        }
      }
    }
  }, []);

  // if (enableManualOverride && sectorItems.length) {
  //   tabs.push({
  //     type: 'manual_sector',
  //     label: 'Our Sector',
  //   });
  // }
  // console.log('sector ', sectorItems);
  // console.log('service ', serviceItems);

  return (
    <>
      {mode == 'news' ? (
        <ChakraBox id={model.system.codename} bg={bg.background} w={'100vw'} mx='auto' maxW={moduleMaxWidth}>
          <OverviewSpotAutomaticNews newsItems={newsItems} />
        </ChakraBox>
      ) : (
        <ChakraBox
          id={model.system.codename}
          p={{ base: 'xs', md: 's', lg: 'l' }}
          bg={bg.background}
          w={'100vw'}
          mx='auto'
          maxW={moduleMaxWidth}
        >
          <Container key={`${locale}`}>
            {
              <Tabs size={{ base: 'base', md: 'md' }} w='100%'>
                <TabList justifyContent={{ base: 'space-between', sm: 'flex-start' }}>
                  {tabs.map((tab, index) => {
                    return <Tab key={index}>{tab.label}</Tab>;
                  })}
                </TabList>
                <TabPanels>
                  {tabs.map((tab, index) =>
                    !tab.type.includes('manual') ? (
                      <TabPanel key={index} pt={{ base: 'unset', lg: 'xs' }}>
                        {displayType == 'accordion' ? (
                          <OverviewSpotAutomaticAccordion
                            key={`${locale}-${index}`}
                            model={
                              tab.type == 'sector' ? sectorItems : tab.type == 'industry' ? industryItems : serviceItems
                            }
                            // enableHyphenationHeading={enableHyphenationHeading}
                            // enableHyphenationSummary={enableHyphenationSummary}
                          />
                        ) : (
                          <OverviewSpotAutomaticGrid
                            key={`${translations.locale}-${index}`}
                            model={
                              tab.type == 'sector' ? sectorItems : tab.type == 'industry' ? industryItems : serviceItems
                            }
                            type={tab.type}
                            overrideTags={getOverrideFlags(tab.type, model)}
                            // enableHyphenationHeading={enableHyphenationHeading}
                            // enableHyphenationSummary={enableHyphenationSummary}
                          />
                        )}
                      </TabPanel>
                    ) : (
                      <TabPanel>
                        <Grid
                          templateColumns={{
                            base: 'repeat(1, 1fr)',
                            md: `repeat(${Math.min(manualOverrideItems.length, 2)}, 1fr)`,
                            lg: `repeat(${Math.min(manualOverrideItems.length, 3)}, 1fr)`,
                          }}
                          gap={{ base: 's', md: 'l' }}
                        >
                          {manualOverrideItems.map((item) => {
                            return <OverrideItem model={item} />;
                          })}
                        </Grid>
                        {/* <OverrideItem model={manualOverrideItems}/> */}
                      </TabPanel>
                    ),
                  )}
                </TabPanels>
              </Tabs>
            }
          </Container>
        </ChakraBox>
      )}
    </>
  );
};

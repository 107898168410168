import {
  Box as ChakraBox,
  Flex as ChakraFlex,
  Grid as ChakraGrid,
  GridItem as ChakraGridItem,
  Text,
} from '@chakra-ui/react';
import { capitalize } from 'lodash';
import { useRouter } from 'next/router';
import React, { FunctionComponent } from 'react';
import { withTranslations } from 'store/translations';

import { LinkType } from '../../lib/enums/LinkType';
import { OverviewItemModel } from '../../utils/types/overviewItemModel';
import { Link } from '../base/Link';
import { HyphenResult } from '../hyphen/hyphenation';

interface OverviewItemProps {
  model: OverviewItemModel[];
  enableHyphenationHeading?: boolean;
  enableHyphenationSummary?: boolean;
  type?: string;
  overrideTags?: string | undefined;
}

export const OverviewSpotAutomaticGrid: FunctionComponent<OverviewItemProps> = ({
  model,
  enableHyphenationHeading,
  enableHyphenationSummary,
  type,
  overrideTags,
}) => {
  const translations = withTranslations();
  const router = useRouter();
  //console.log('overview spot automatic grid ', model);
  //const tagOverride =
  return (
    <ChakraFlex>
      <ChakraGrid
        templateColumns={{
          base: 'repeat(1, 1fr)',
          md: `repeat(${Math.min(model.length, 2)}, 1fr)`,
          lg: `repeat(${Math.min(model.length, 3)}, 1fr)`,
        }}
        gap={{ base: 's', md: 'l' }}
      >
        {model.map((item) => {
          return (
            <ChakraGridItem key={item.id} maxW='380px'>
              <ChakraFlex direction='column'>
                <ChakraBox textStyle='desktop.body.book.s' minH='16px'>
                  {overrideTags && overrideTags !== 'no_override' ? overrideTags : item.tag}
                </ChakraBox>
                <ChakraBox as='h3' textStyle={{ base: 'mobile.h4', md: 'desktop.h4' }} pt='xxs' pb='xs'>
                  {item.headline
                    ? item.enableHyphenation == 'yes'
                      ? HyphenResult(item.headline, router.locale)
                      : item.headline
                    : ''}
                </ChakraBox>
                <ChakraBox textStyle={{ base: 'desktop.body.book.s', md: 'desktop.body.l' }}>
                  {item.text?.map((text, index) => (
                    <Text key={index}>
                      {text.body
                        ? item.enableHyphenation == 'yes'
                          ? HyphenResult(text.body, router.locale)
                          : text.body
                        : ''}
                    </Text>
                  ))}
                </ChakraBox>
                <ChakraBox pt='32px'>
                  {item.link && item.linkText && (
                    <Link
                      href={item.link}
                      type={LinkType.SecondaryButton}
                      iconName='chevronRightForLink'
                      locale={router.locale}
                    >
                      {translations.common(item.linkText)}
                    </Link>
                  )}
                </ChakraBox>
              </ChakraFlex>
            </ChakraGridItem>
          );
        })}
      </ChakraGrid>
    </ChakraFlex>
  );
};
